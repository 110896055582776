import React from 'react';
import { graphql } from 'gatsby';

import Banner from '@components/banner';
import Layout from '@components/layout';
import SEO from '@components/seo';

const ContactForm = () => (
  <form
    action="https://formspree.io/contact@creativeclowns.se"
    method="POST"
    className="form"
  >
    <p>
      <label>
        Your Name: <input type="text" name="name" />
      </label>
    </p>
    <p>
      <label>
        Your Email: <input type="email" name="email" />
      </label>
    </p>
    <p>
      <label>
        Message: <textarea name="message"></textarea>
      </label>
    </p>
    <div className="bannerButtons">
      <p>
        <button type="submit" className="primButton blog">
          Send
        </button>
      </p>
    </div>
  </form>
);

const IndexPage = data => (
  <Layout>
    <SEO title="Shop" keywords={[`gatsby`, `application`, `react`]} />
    <Banner BannerData={data.data.allContentfulPageBanner.edges} />
    <div className="container">
      <ContactForm />
    </div>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        url
      }
    }
    allContentfulPageBanner(filter: { page: { eq: "contact" } }) {
      edges {
        node {
          title
          subHeading
          page
          image {
            fluid(maxWidth: 1800) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`;
